import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-in-container" }
const _hoisted_2 = {
  id: "firebaseui-auth-container",
  ref: "firebaseui-auth-container"
}

import { onMounted, onUnmounted } from 'vue'
import { authCompat } from "@/firebase"
import { authSettings } from "@/firebase/config"
import firebase from 'firebase/compat/app'
import * as firebaseui from "firebaseui"
import "firebaseui/dist/firebaseui.css"


export default /*@__PURE__*/_defineComponent({
  __name: 'SignInForm',
  setup(__props) {

let ui: firebaseui.auth.AuthUI | null = null

onMounted(() => {
  const uiConfig = {
    ...authSettings,
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: 'ME',
        whitelistedCountries: ['ME'],
        loginHint: '+382',
        recaptchaParameters: {
          size: 'invisible',
          badge: 'bottomleft'
        }
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any) => {
        console.log('Auth result:', authResult)
        return false
      },
      signInFailure: (error: firebaseui.auth.AuthUIError) => {
        console.error('Auth error:', error)
      }
    }
  }

  ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(authCompat)
  ui.start("#firebaseui-auth-container", uiConfig)
})

onUnmounted(() => {
  if (ui) {
    ui.delete()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512)
  ]))
}
}

})